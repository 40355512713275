// pages/_app.js
import "tailwindcss/tailwind.css";
import Head from "next/head";
import Script from "next/script";
import { useEffect } from "react";
import Analytics from "../components/Analytics/Analytics";

import "./_app.css";

// Utility function to extract the content attribute from the meta tag string
function extractContentFromMetaTag(metaTagString) {
  const match = metaTagString.match(/content="([^"]*)"/);
  return match ? match[1] : "";
}

function MyApp({ Component, pageProps }) {
  // Extract the content value from the meta tag string
  const verificationContent = process.env.NEXT_GOOGLE_SITE_VER_CODE
    ? extractContentFromMetaTag(process.env.NEXT_GOOGLE_SITE_VER_CODE)
    : "";

  useEffect(() => {
    // Inject MISC_SCRIPTS into body
    if (process.env.NEXT_PUBLIC_MISC_SCRIPTS) {
      const miscScript = document.createElement("script");
      miscScript.innerHTML = process.env.NEXT_PUBLIC_MISC_SCRIPTS;
      document.body.appendChild(miscScript);
    }
  }, []);

  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_META_TITLE || ""}</title>
        <meta
          name="description"
          content={process.env.NEXT_PUBLIC_META_DESCRIPTION || ""}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content="#fcf4ed" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <meta
          property="og:title"
          content={process.env.NEXT_PUBLIC_META_TITLE || ""}
        />
        <meta
          property="og:description"
          content={process.env.NEXT_PUBLIC_META_DESCRIPTION || ""}
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={process.env.NEXT_PUBLIC_META_TITLE || ""}
        />
        <meta
          name="twitter:description"
          content={process.env.NEXT_PUBLIC_META_DESCRIPTION || ""}
        />

        {/* Inject Google site verification meta tag */}
        {verificationContent && (
          <meta name="google-site-verification" content={verificationContent} />
        )}

        {/* Inject CUSTOM_CSS */}
        {process.env.NEXT_PUBLIC_CUSTOM_CSS && (
          <style
            dangerouslySetInnerHTML={{
              __html: process.env.NEXT_PUBLIC_CUSTOM_CSS,
            }}
          />
        )}
      </Head>

      {/* Include custom head scripts using next/script */}
      {process.env.NEXT_PUBLIC_HEAD_SCRIPTS && (
        <Script id="custom-head-scripts" strategy="afterInteractive">
          {process.env.NEXT_PUBLIC_HEAD_SCRIPTS}
        </Script>
      )}

      {/* Include Google Analytics script */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-967035950"
        strategy="afterInteractive"
      />

      <Component {...pageProps} />
      <Analytics />
    </>
  );
}

export default MyApp;
